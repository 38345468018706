<template>
  <section class="download section-padding">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sectioner-header text-center white">
            <h3>Download Our App</h3>
            <span class="line"></span>
            <p class="white">
              Unlock a world of learning and fun – download our app today! Join thousands of happy users and discover a
              wealth of educational content designed for young minds. Get started on your learning journey now!
            </p>
          </div>
        </div>
        <div class="col-md-12">
          <div class="section-content text-center">
            <ul>
              <li>
                <a href="https://play.google.com/store/apps/details?id=app.learnnjoy.ziancetech" target="_blank"><img src="frontend/images/playstore.png" class="wow fadeInUp" data-wow-delay="0.7s" /></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12" v-if="route.path === '/'">
          <h5 class="text-center text-white">
            <router-link to="/privacy-policy" class="white">Privacy Policy</router-link>
          </h5>
        </div>
      </div>
    </div>
  </section>

  <!-------Download End------->

  <footer class="footer-copy">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <p>
            2024 &copy; All rights are reserved. Website Designed by
            <a href="https://ziancetechnologies.com" target="_blank" rel="dofollow">Ziance Technologies</a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    onMounted(async () => {
      await router.isReady();
    });

    return { route };
  },
};

// import  useRoute  from "vue-router";
// import { computed } from "vue";

// const route = useRoute();
// const path = computed(() => route.path);

</script>
