<template>
  <!-- Navbar -->
  <!-- <nav class="navbar navbar-expand-lg">
  <div class="container"> <a class="navbar-brand navbar-logo " href="#"> <img src="frontend/images/LogoWhite.png" alt="logo" class="logo-1 logo-black"> </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> <span class="fas fa-bars"></span> </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item"> <a class="nav-link" href="" data-scroll-nav="0">Home</a> </li>
        <li class="nav-item"> <a class="nav-link" href="/" data-scroll-nav="1">About</a> </li>
        <li class="nav-item"> <a class="nav-link" href="/" data-scroll-nav="2">Features</a> </li>
        <li class="nav-item"> <a class="nav-link" href="/" data-scroll-nav="3">Team</a> </li>
        <li class="nav-item"> <a class="nav-link" href="/" data-scroll-nav="4">Testimonials</a> </li>
        <li class="nav-item"> <a class="nav-link" href="/" data-scroll-nav="5">Faq</a> </li>
        <li class="nav-item"> <a class="nav-link" href="/" data-scroll-nav="6">Contact</a> </li>
     
      </ul>
    </div>
  </div>
</nav> -->

  <!-------About Start------->

  <CommonHeader />
  <section class="about section-padding prelative" data-scroll-index="1">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sectioner-header text-center">
            <h3>About</h3>
            <span class="line"></span>
            <p>
              In the Learn & Joy mobile application, users can expect to find a variety of
              features and functionalities designed to enhance their learning experience.
              Learn & Joy aims to provide a comprehensive and engaging learning platform
              that caters to the diverse needs and interests of its users.
            </p>
          </div>
          <div class="section-content text-center">
            <div class="row">
              <div class="col-md-4">
                <div class="icon-box wow fadeInUp" data-wow-delay="0.2s">
                  <i class="fa fa-life-ring" aria-hidden="true"></i>
                  <h5>Our Mission</h5>
                  <p>
                    At Learn & Joy, our mission is to provide a platform where learning is
                    accessible, enjoyable, and rewarding for everyone. We believe that
                    education is the key to personal and professional growth, and we are
                    committed to empowering our users to reach their full potential.
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="icon-box wow fadeInUp" data-wow-delay="0.4s">
                  <i class="fa fa-mobile" aria-hidden="true"></i>
                  <h5>Our Team</h5>
                  <p>
                    Behind Learn & Joy is a team of dedicated professionals who are
                    passionate about education and technology. Our team includes experts
                    in education, design, development, and content creation, all working
                    together to create a seamless and enriching learning experience for
                    our users.
                  </p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="icon-box wow fadeInUp" data-wow-delay="0.6s">
                  <i class="fa fa-bolt" aria-hidden="true"></i>
                  <h5>Our Values</h5>
                  <p>
                    At Learn & Joy, we are guided by a set of core values that shape
                    everything we do. These values include a commitment to excellence,
                    innovation, inclusivity, and integrity.
                    We are dedicated to upholding these values in every aspect of our work, from the content we create
                    to our interactions with users.
                  </p>
                </div>
              </div>
            </div>
            <a href="#" class="about-btn">Learn More</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-------About End------->

  <!-------Video Start------->
  <section class="video-section prelative text-center white">
    <div class="section-padding video-overlay">
      <div class="container">
        <h3>Watch Now</h3>
        <i class="fa fa-play" id="video-icon" aria-hidden="true"></i>
        <div class="video-popup">
          <div class="video-src">
            <div class="iframe-src">
              <iframe src="https://www.youtube.com/embed/Ku52zNnft8k?rel=0&amp;showinfo=0" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-------Video End------->

  <!-------Features Start------->
  <section class="feature section-padding" data-scroll-index="2">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sectioner-header text-center">
            <h3>Features</h3>
            <span class="line"></span>
            <p>
              Explore a wealth of features designed to engage and educate, from interactive games to informative
              lessons. Unlock a world of learning with our app!
            </p>
          </div>
          <div class="section-content text-center">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div class="media single-feature wow fadeInUp" data-wow-delay="0.2s">
                  <div class="media-body text-right media-right-margin">
                    <h5>Basic Concepts</h5>
                    <p>
                      Lessons and activities that teach fundamental mathematical concepts such as numbers, operations
                      (addition, subtraction, multiplication, division), fractions, decimals, and percentages.
                    </p>
                  </div>
                  <div class="media-right icon-border">
                    <span class="fa fa-bolt" aria-hidden="true"></span>
                  </div>
                </div>
                <div class="media single-feature wow fadeInUp" data-wow-delay="0.4s">
                  <div class="media-body text-right media-right-margin">
                    <h5>Letter Recognition</h5>
                    <p>
                      Interactive lessons and games to help children recognize and differentiate between uppercase and
                      lowercase letters.
                    </p>
                  </div>
                  <div class="media-right icon-border">
                    <span class="fas fa-sort-alpha-up" aria-hidden="true"></span>
                  </div>
                </div>
                <div class="media single-feature wow fadeInUp" data-wow-delay="0.6s">
                  <div class="media-body text-right media-right-margin">
                    <h5>Sense Organs</h5>
                    <p>
                      These educational content ideas can be adapted and expanded to create engaging and informative
                      lessons for children in the Learn & Joy app.
                    </p>
                  </div>
                  <div class="media-right icon-border">
                    <span class="far fa-eye" aria-hidden="true"></span>
                  </div>
                </div>
              </div>
              <div class="col-md-4 d-none d-md-block d-lg-block">
                <div class="feature-mobile">
                  <img src="frontend/images/iphone-screen.jpg" class="img-fluid wow fadeInUp" />
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div class="media single-feature wow fadeInUp" data-wow-delay="0.2s">
                  <div class="media-left icon-border media-right-margin">
                    <span class="fa fa-i-cursor" aria-hidden="true"></span>
                  </div>
                  <div class="media-body text-left">
                    <h5>Solar System</h5>
                    <p>
                      Interactive tools to learn about the planets in our solar system, their characteristics, and their
                      orbit around the sun.
                    </p>
                  </div>
                </div>
                <div class="media single-feature wow fadeInUp" data-wow-delay="0.4s">
                  <div class="media-left icon-border media-right-margin">
                    <span class="fas fa-user" aria-hidden="true"></span>
                  </div>
                  <div class="media-body text-left">
                    <h5>User-Friendly Interface</h5>
                    <p>
                      The app features a user-friendly interface that is easy to navigate,
                      ensuring a seamless learning experience for users of all ages and
                      backgrounds.
                    </p>
                  </div>
                </div>
                <div class="media single-feature wow fadeInUp" data-wow-delay="0.6s">
                  <div class="media-left icon-border media-right-margin">
                    <span class="fa fa-hdd" aria-hidden="true"></span>
                  </div>
                  <div class="media-body text-left">
                    <h5>Accessibility Features</h5>
                    <p>
                      Benefit from accessibility features such as text-to-speech and
                      adjustable font sizes to accommodate diverse learning needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-------Features End------->

  <!-------Team Start------->
  <!-- <section class="team section-padding" data-scroll-index="3">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sectioner-header text-center">
            <h3>Our Team</h3>
            <span class="line"></span>
            <p>
              Sed quis nisi nisi. Proin consectetur porttitor dui sit amet viverra. Fusce
              sit amet lorem faucibus, vestibulum ante in, pharetra ante.
            </p>
          </div>
          <div class="section-content text-center">
            <div class="row">
              <div class="col-md-4">
                <div class="team-detail wow bounce" data-wow-delay="0.2s">
                  <img src="frontend/images/user1.jpg" class="img-fluid" />
                  <h4>Nitu Singh</h4>
                  <p>Marketing Specialist</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="team-detail wow bounce" data-wow-delay="0.4s">
                  <img src="frontend/images/user2.jpg" class="img-fluid" />
                  <h4>Yogesh Singh</h4>
                  <p>CEO & Founder</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="team-detail wow bounce" data-wow-delay="0.6s">
                  <img src="frontend/images/user3.jpg" class="img-fluid" />
                  <h4>Nisha Sharma</h4>
                  <p>Web Developer</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-------Team End------->

  <!-------Testimonial Start------->
  <section class="testimonial section-padding" data-scroll-index="3">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sectioner-header text-center white">
            <h3>Testimonials</h3>
            <span class="line"></span>
            <p class="white">
              See What Others Are Saying About Us! Discover Why Our Users Love Our App and How It's Making a Difference
              in Their Lives!
            </p>
          </div>

          <div class="section-content">
            <div class="row">
              <div class="offset-md-2 col-md-8 col-sm-12">
                <div class="slider">
                  <div class="slider-item">
                    <div class="test-img">
                      <img src="frontend/images/user1.jpg" alt="Placeholder" width="157" height="157" />
                    </div>
                    <div class="test-text">
                      <span class="title"><span>Sarah, </span> Parent</span>
                      "As a busy parent, I appreciate how convenient Learn & Joy is. The
                      app's offline access feature is a great one, it permits my children
                      to continue learning even when we're on the go or without internet
                      access. It's been a lifesaver during long car rides or when we're
                      traveling. I couldn't be happier with the app."
                    </div>
                  </div>
                  <div class="slider-item">
                    <div class="test-img">
                      <img src="frontend/images/user3.jpg" alt="Placeholder" width="157" height="157" />
                    </div>
                    <div class="test-text">
                      <span class="title"><span>Michael, </span> Parent</span> Learn & Joy
                      has made learning fun and accessible for my children. The app's
                      interactive lessons and gamified learning experiences have
                      encouraged them to learn. I love that they can learn at their own
                      pace and explore topics that interest them. It's been a valuable
                      tool for their education.
                    </div>
                  </div>
                  <div class="slider-item">
                    <div class="test-img">
                      <img src="frontend/images/user3.jpg" alt="Placeholder" width="157" height="157" />
                    </div>
                    <div class="test-text">
                      <span class="title"><span>Rachel, </span> Parent</span>
                      As a parent, I'm always looking for ways to supplement my children's
                      education. Learn & Joy has been a fantastic resource for us. The
                      app's personalized learning paths have helped my children stay
                      focused and motivated, and the ability to track their progress has
                      been incredibly helpful. I highly recommend it to other parents.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-------Testimonial End------->

  <!-------FAQ Start------->
  <!-- <section class="faq section-padding prelative" data-scroll-index="5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sectioner-header text-center">
            <h3>Frequently Asked Questions</h3>
            <span class="line"></span>
            <p>
              Sed quis nisi nisi. Proin consectetur porttitor dui sit amet viverra. Fusce
              sit amet lorem faucibus, vestibulum ante in, pharetra ante.
            </p>
          </div>
          <div class="section-content">
            <div class="row">
              <div class="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.2s">
                <h4>Nam tellus felis, dignissim quis dui ?</h4>
                <p>
                  Suspendisse fermentum placerat enim, at pellentesque augue. Nullam elit
                  est, ultricies et tellus ac, euismod placerat orci. Donec commodo.
                </p>
              </div>
              <div class="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.2s">
                <h4>Mauris scelerisque, dui non faucibus vulputate ?</h4>
                <p>
                  Sed tempus in neque ac rhoncus. Phasellus vehicula, erat tempor
                  malesuada egestas, mauris tellus malesuada erat, at vestibulum nulla ex
                  et lectus. Nullam elit est, ultricies et tellus ac, euismod placerat
                  orci.
                </p>
              </div>
              <div class="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.4s">
                <h4>Nullam elit est, ultricies et tellus ac ?</h4>
                <p>
                  Ut vestibulum euismod aliquet. Quisque nec malesuada nibh. Vivamus
                  euismod nunc eu leo faucibus, vel elementum justo posuere. In sed varius
                  nisi. Curabitur id porta ipsum, et vestibulum dui.
                </p>
              </div>
              <div class="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.4s">
                <h4>
                  Suspendisse fermentum placerat enim, at pellentesque augue elit est ?
                </h4>
                <p>
                  Vivamus euismod nunc eu leo faucibus, vel elementum justo posuere. In
                  sed varius nisi.
                </p>
              </div>
              <div class="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.6s">
                <h4>Ut vestibulum euismod aliquet. Quisque nec malesuada nibh ?</h4>
                <p>
                  Suspendisse fermentum placerat enim, at pellentesque augue. Nullam elit
                  est, ultricies et tellus ac, euismod placerat orci. Donec commodo
                  dapibus congue.
                </p>
              </div>
              <div class="col-md-6 faq-content wow fadeInUp" data-wow-delay="0.6s">
                <h4>Donec commodo dapibus congue ?</h4>
                <p>
                  Nullam elit est, ultricies et tellus ac, euismod placerat orci fermentum
                  placerat enim, at pellentesque augue. Nullam elit est, ultricies et
                  tellus ac, euismod placerat orci. Donec commodo dapibus congue.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-------FAQ End------->

  <!-------Contact Start------->
  <section class="contact section-padding" data-scroll-index="4">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="sectioner-header text-center">
            <h3>Contact us</h3>
            <span class="line"></span>
            <p>
              We'd love to hear from you! Whether you have a question about our app, need assistance, or just want to
              say hello, feel free to reach out to us using the form below or through our contact information.
            </p>
          </div>
          <div class="section-content">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="contact-info white">
                  <div class="contact-item media">
                    <i class="fa fa-map-marker-alt media-left media-right-margin"></i>
                    <div class="media-body">
                      <p class="text-uppercase">Address</p>
                      <p class="text-uppercase">
                        E-821/822, Ganesh Glory 11, Jagatpur Road, Off. S.G. Highway,
                        Gota, Ahmedabad - 382470
                      </p>
                    </div>
                  </div>
                  <div class="contact-item media">
                    <i class="fa fa-mobile media-left media-right-margin"></i>
                    <div class="media-body">
                      <p class="text-uppercase">Phone</p>
                      <p class="text-uppercase">
                        <a class="text-white" href="tel:+917383664539">+91-738-366-4539</a>
                      </p>
                    </div>
                  </div>
                  <div class="contact-item media">
                    <i class="fa fa-envelope media-left media-right-margin"></i>
                    <div class="media-body">
                      <p class="text-uppercase">E-mail</p>
                      <p class="text-uppercase">
                        <a class="text-white" href="mailto:support@learnnjoy.app">support@learnnjoy.app</a>
                      </p>
                    </div>
                  </div>
                  <div class="contact-item media">
                    <i class="fa fa-clock media-left media-right-margin"></i>
                    <div class="media-body">
                      <p class="text-uppercase">Working Hours</p>
                      <p class="text-uppercase">Mon-Fri 10.00 AM to 7.00 PM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.5335803054872!2d72.53768057600985!3d23.1141640129026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e8342244706e7%3A0x91521cf8d4b208a0!2sZiance%20Technologies!5e0!3m2!1sen!2sin!4v1710248995872!5m2!1sen!2sin"
                  width="600" height="450" style="border: 0" allowfullscreen="" loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-------Contact End------->
  <CommonFooter />
  <!-------Download Start------->
</template>

<script>
import CommonFooter from "@/components/CommonFooter.vue";
import CommonHeader from "@/components/CommonHeader.vue";
export default {
  name: "MasterView",
  components: {
    CommonHeader: CommonHeader,
    CommonFooter: CommonFooter,
  },
};
</script>

<style></style>
