<template>
  <!-- <MasterView> -->

  <!-- End Navbar -->
  <!-------Banner Start------->

  <!-- </MasterView> -->
  <router-view />
</template>

<script>
import MasterView from "./views/MasterView.vue";
import CommonHeader from "./components/CommonHeader.vue";
export default {
  component: MasterView,
  components: {
    CommonHeader: CommonHeader,
  },
};
</script>

<style></style>
