<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container">
      <a class="navbar-brand navbar-logo" href="#">
        <img src="frontend/images/LogoWhite.png" alt="logo" class="logo-1 logo-black" />
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="fas fa-bars"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item" v-show="route.path === '/privacy-policy'">
            <a class="nav-link" href="/">Home</a>
          </li>
          <li class="nav-item" v-show="route.path === '/'">
            <a class="nav-link" href="#" data-scroll-nav="0">Home</a>
          </li>
          <li class="nav-item" v-show="route.path === '/'">
            <a class="nav-link" href="/" data-scroll-nav="1">About</a>
          </li>
          <li class="nav-item" v-show="route.path === '/'">
            <a class="nav-link" href="/" data-scroll-nav="2">Features</a>
          </li>
          <!-- <li class="nav-item" v-show="route.path === '/'">
            <a class="nav-link" href="/" data-scroll-nav="3">Team</a>
          </li> -->
          <li class="nav-item" v-show="route.path === '/'">
            <a class="nav-link" href="/" data-scroll-nav="3">Testimonials</a>
          </li>
          <!-- <li class="nav-item" v-show="route.path === '/'">
            <a class="nav-link" href="/" data-scroll-nav="5">Faq</a>
          </li> -->
          <li class="nav-item" v-show="route.path === '/'">
            <a class="nav-link" href="/" data-scroll-nav="4">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <section class="banner" data-scroll-index="0">
    <div class="banner-overlay">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-sm-12">
            <div class="banner-text">
              <h2 class="white">Learn &amp; Joy</h2>
              <h6 class="white">
                This awesome app designed by
                <a href="https://www.ziancetechnologies.com" target="_blank" rel="dofollow" class="weblink">Ziance
                  Technologies</a>.
              </h6>
              <p class="banner-text white">
                Welcome to Learn & Joy, your go-to mobile application for engaging and
                effective learning experiences!
                We are committed to offering a platform where young children can connect from the comfort of their homes
                to expand their knowledge and skills.
              </p>
              <ul>
                <li>
                  <a href="https://play.google.com/store/apps/details?id=app.learnnjoy.ziancetech" target="_blank"><img src="frontend/images/playstore.png" class="wow fadeInUp" data-wow-delay="0.7s" /></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-4 col-sm-12">
            <img src="frontend/images/iphone-screen.jpg" class="img-fluid wow fadeInUp" />
          </div>
        </div>
      </div>
    </div>
    <span class="svg-wave">
      <img class="svg-hero" src="frontend/images/applight-wave.svg" />
    </span>
  </section>
</template>

<script>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    onMounted(async () => {
      await router.isReady();
    });

    return { route };
  },
};

// import  useRoute  from "vue-router";
// import { computed } from "vue";

// const route = useRoute();
// const path = computed(() => route.path);

</script>
